
export const archData = [
  {
    image:
     "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m203swie.png",
    name: "varun satia",
    designation: "Founder and Chief Story Teller, Kraftshala",
    past: `<div>Ex - Nestle (Marketing @ KitKat & MAGGI) Ex - India MD @ Campus Ventures Inc.</div>
    <div>MBA from FMS, Delhi</div>
    <div>B.E. from BITS Pilani</div>`,
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uazq3k.png",
    name: "eshu sharma",
    designation: "Co-Founder & Head of Student Experience, Kraftshala",
    past: `<div>Ex - Unilever</div>
    <div>MBA from FMS, Delhi</div>
    <div>B.Tech from PEC, Chandigarh</div>`,
  },
  {
    image:
     "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m203etip.png",
    name: "nishtha jain",
    designation: "Head of Marketing & Content, Kraftshala",
    past: `<div>Ex - Nestle Brand Manager (MAGGI)</div>
      <div>MBA from IIM Lucknow</div>
      <div>B.Tech from NIT, Kkr</div>`,
  },
  // {
  //   image:
  //     "https://kfwebassets.s3.ap-south-1.amazonaws.com/IndustryCredsExperience/sumona.png",
  //   name: "sumona shetty",
  //   designation: "Human Skills Training, Kraftshala",
  //   past: `<div>ex-Teach For India.</div>
  //   <div>PG Diploma in Counselling from TISS</div>`,
  // },
];
