
export const programs = [
  {
    tabID: "1",
    groupHead: "for Entry level roles",
    groupContent: "test",
  },
  {
    tabID: "2",
    groupHead: "for Manager level roles",
    groupContent: "test",
  },
  {
    tabID: "3",
    groupHead: "for B-School Students",
    groupContent: "test",
  },
];

export const programData = [
  {
    groupID: "1",
    courses: [
      {
        image_url:
    "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        image2_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        name: '<div>The Marketing Launchpad <span style="font-weight: 100">- Full Time</span> <div>',
        gatsbyRoute: "/marketing-launchpad",
        mainRoute: "https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/",
        trademark: true,
        about:
          "<div>This is a <b>20 week online, full-time digital marketing course with specialisations in Digital Media, Content & Social Media</b> for which we select, train and invest in high potential graduates to help them start a fantastic career in marketing. The best part? You ONLY pay when you land a job with a minimum CTC of INR 4.5 lakhs.</div>",
        about2:
          "This is a 20 week online, full-time digital marketing course with specialisations in Digital Media, Content & Social Media for which we select, train and invest in high potential graduates to help them start a fantastic career in marketing. The best part? You ONLY pay when you land a job with a minimum CTC of INR 4.5 lakhs. And if the job < 4.5LPA, you pay nothing.",
      },
      {
        image_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        image2_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        name: '<div>The Marketing Launchpad <span style="font-weight: 100">- Part Time</span> <div>',
        gatsbyRoute: "/marketing-launchpad/part-time-digital-marketing-course",
        mainRoute: "https://www.kraftshala.com/marketing-launchpad/part-time-digital-marketing-course/",
        trademark: true,
        about:
          "<div>This is a <b>30 week online, part-time program with specialization in Digital Paid Media Marketing </b> that includes an 8 week internship. We select, train and invest in high potential graduates to help them kickstart their marketing career. You only pay if you land a job with a minimum CTC of ₹ 4.5 lakhs.</div>",
        about2:
          "This is a 30 week, online, part-time program with specialization in Digital Paid Media Marketing that includes an 8 week internship. We select, train and invest in high potential graduates to help them kickstart their marketing career. You only pay if you land a job with a minimum CTC of ₹ 4.5 lakhs.",
      },
    ],
  },
  {
    groupID: "2",
    courses: [
      {
        image_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        image2_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,

        name: "Professional Brand Management In The Digital Age",
        gatsbyRoute: "/professional-brand-management-in-the-digital-age",
        mainRoute:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        about:
          "<div>This is an intensive online course which delivers decades worth of hard-earned insights & industry experience in 22 weeks.You learn side-by-side from industry experts who’ve built their careers growing brands across sectors and industries and leveraging traditional and digital media to drive business growth</div>",
        about2:
          "This is an intensive online course which delivers decades worth of hard-earned insights & industry experience in 22 weeks. You learn side-by-side from industry experts who’ve built their careers growing brands across sectors and industries and leveraging traditional and digital media to drive business growth",
      },
    ],
  },
  {
    groupID: "3",
    courses: [
      {
        image_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        image2_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,

        name: "Digital Brand Building",
        gatsbyRoute: "/digital-brand-building",
        mainRoute: "https://www.kraftshala.com/digital-brand-building",
        about:
          "This online course from Kraftshala combines Brand Building and Digital Marketing in a comprehensive structure that you can use to find the 'job-to-do' and then ’solve' any brand growth problems using both communication and media - a favourite for any aspiring brand manager.",
        about2:
          "This online course from Kraftshala combines Brand Building and Digital Marketing in a comprehensive structure that you can use to find the 'job-to-do' and then ’solve' any brand growth problems using both communication and media - a favourite for any aspiring brand manager / performance / growth manager",
      },
      {
        image_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        image2_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        name: "Sales Leadership",
        gatsbyRoute: "/sales-leadership",
        mainRoute: "https://www.kraftshala.com/sales-leadership",
        about:
          "The only online course in the country that will not only take you through the sales & distribution structure of multiple sectors like FMCG, FMCD, E-commerce, Telecom, etc. but also teach you the frameworks that sales managers use to grow their categories.",
        about2:
          "The only online course in the country that will not only take you through the sales & distribution structure of multiple sectors like FMCG, FMCD, E-commerce, Telecom, etc. but also teach you the frameworks that sales managers use to grow their categories.",
      },
      {
        image_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        image2_url:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m2092ym6.png"
        ,
        mainRoute: "https://www.kraftshala.com/brand-building/",
        name: "Brand Building",
        gatsbyRoute: "/brand-building",
        about:
          "This online course for B-School deep dives into the process of creating communication in an extensive manner keeping in mind the category, competition, your brand & the user.",
        about2:
          "This online course for B-School deep dives into the process of creating communication in an extensive manner keeping in mind the category, competition, your brand & the user.",
      },
    ],
  },
];
